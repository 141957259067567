<template>
  <b-container class="container-rda siderbar" style="max-width: 100%">
    <b-row class="height">
      <b-col class="content">
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <h2 class="purple bold">Link de matrícula</h2>
          </b-col>
          <b-col class="text-right">
            <b-button class="btn large blue" @click="openModal"
              >Criar novo link</b-button
            >
          </b-col>
        </b-row>

        <b-row align="center" v-if="loading">
          <b-col class="mt-5">
            <b-spinner variant="success" label="Carregando..."></b-spinner>
          </b-col>
        </b-row>

        <div v-if="tokenData.link">
          <b-container class="list-top" fluid>
            <b-row class="header-list-teacher">
              <b-col md="7">
                <p class="bold">Link</p>
              </b-col>

              <b-col md="2">
                <p class="bold">Expira em</p>
              </b-col>

              <b-col md="1">
                <p class="bold">Status</p>
              </b-col>

              <b-col md="1">
                <p class="bold">Copiar</p>
              </b-col>

              <b-col md="1">
                <p class="bold">Deletar</p>
              </b-col>
            </b-row>
          </b-container>

          <b-container fluid>
            <b-row align-v="center" class="list-teacher">
              <b-col md="7" class="name">
                <p style="word-wrap: break-word">{{ tokenData.link }}</p>
              </b-col>

              <b-col md="2" class="name">
                <p class>{{ tokenData.expiresAt }}</p>
              </b-col>

              <b-col md="1" class="name">
                <p
                  :class="
                    tokenStatus === 'Expirado'
                      ? 'statuTextExpired'
                      : 'statuTextValid'
                  "
                >
                  {{ tokenStatus }}
                </p>
              </b-col>

              <b-col style="cursor: pointer" md="1" class="name">
                <a @click="() => copyLink()">
                  <Clipboard />
                </a>
              </b-col>

              <b-col style="cursor: pointer" md="1" class="name">
                <a @click="() => deleteLink()">
                  <DeleteIcon />
                </a>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <b-row v-else>
          <b-col>
            <p>Nenhum link de matrícula disponível</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal
      id="modal-create-link"
      hide-footer
      style="display: flex; justify-content: center; align-items: center"
      title="Criar Novo Link de Matrícula"
    >
      <b-row>
        <b-col>
          <b-form-group class="required" description>
            <label for="label">Disponível de</label>
            <b-form-input
              v-model="newLink.beginDate"
              type="date"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group class="required" description>
            <label for="label">Disponível até</label>
            <b-form-input
              v-model="newLink.endDate"
              type="date"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-button
            id="createLinkBtn"
            class="btn small blue"
            @click="createLink"
          >
            Criar link
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import httpRda from "../../../http-rda";
import moment from "moment-timezone";
import Clipboard from "@/components/svg/Clipboard.vue";
import DeleteIcon from "../../../components/svg/DeleteIcon.vue";

export default {
  components: {
    Clipboard,
    DeleteIcon,
  },

  data() {
    return {
      name: "links",
      loading: false,

      newLink: {
        beginDate: "",
        endDate: "",
      },

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Link de Matrícula",
          href: "/administracao/eventos",
        },
      ],
      tokenData: {
        token: "",
        expiresAt: "",
        link: "",
      },
      tokenStatus: "",
    };
  },

  methods: {
    successMessage(title = "Link copiado para a área de transferência") {
      this.$toast.open({
        message: title,
        type: "success",
        duration: 2000,
      });
    },

    errorMessage(title) {
      this.$toast.open({
        message: title,
        type: "error",
        duration: 2000,
      });
    },

    copyLink() {
      const el = document.createElement("textarea");
      el.value = this.tokenData.link;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.successMessage();
    },

    async deleteLink() {
      this.$swal
        .fire({
          title: "Deseja deletar esse link de matrícula?",
          text: "Os usuários não poderão mais se matricular com esse link",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;

            try {
              await httpRda.delete("/delete-enrollment-token").then(() => {
                this.getLink();
                this.loading = false;
              });
            } catch (error) {
              this.errorMessage("Não foi possível deletar o link!");
              this.loading = false;
            }
          }
        });
    },

    openModal() {
      this.$bvModal.show("modal-create-link");
    },

    async createLink() {
      this.loading = true;

      if (!this.newLink.beginDate || !this.newLink.endDate) {
        this.errorMessage("Preencha todos os campos");

        this.loading = false;
        return;
      }

      const now = moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      if (this.newLink.beginDate > this.newLink.endDate) {
        this.errorMessage(
          "A data de início não pode ser maior que a data final"
        );

        this.loading = false;
        return;
      }

      if (moment(this.newLink.beginDate).isBefore(now)) {
        this.errorMessage(
          "A data de início não pode ser menor que a data atual"
        );

        this.loading = false;
        return;
      }

      if (moment(this.newLink.endDate).isBefore(now)) {
        this.errorMessage("A data final não pode ser menor que a data atual");

        this.loading = false;
        return;
      }

      try {
        await httpRda
          .post("/generate-enrollment-token", {
            beginDate: this.newLink.beginDate,
            endDate: this.newLink.endDate,
          })
          .then(() => {
            this.newLink = {
              beginDate: "",
              endDate: "",
            };

            this.getLink();

            this.loading = false;
            this.$bvModal.hide("modal-create-link");
          });
      } catch (error) {
        this.errorMessage("Não foi possível criar o link!");
        this.loading = false;
      }
    },

    async getLink() {
      this.loading = true;

      try {
        await httpRda
          .get("/enrollments/get-enrollment-token")
          .then((response) => {
            this.tokenData.token = response.data.token;
            this.tokenData.link = response.data.link;
            this.tokenData.expiresAt = moment(response.data.expiresAt).format(
              "DD/MM/YYYY"
            );

            this.tokenStatus = moment(response.data.expiresAt).isBefore(
              moment().set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
            )
              ? "Expirado"
              : "Ativo";

            this.loading = false;
          });
      } catch (e) {
        this.loading = false;
      }
    },
  },

  created() {
    this.getLink();
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.modal-dialog {
  max-width: 820px;
  .modal-content {
    background-color: $grey2;
    border: 0;
    border-radius: 0;
    .modal-body {
      padding: 60px;
    }
  }
}

.list-teacher {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;
  &:hover {
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0;
  }
  .disciplines {
    .name {
      display: inline-block;
      line-height: 22px;
      .comma {
        margin-right: 4px;
      }
      &:last-child {
        .comma {
          display: none;
        }
      }
    }
  }
  .cycles {
    display: flex;
    flex-wrap: wrap;
    .tags {
      background-color: $blue;
      border-radius: 5px;
      color: $white;
      font-weight: $bold;
      line-height: 28px;
      margin: 3px 12px 3px 0;
      max-width: 58px;
      padding: 0 3px;
      text-align: center;
      vertical-align: middle;
    }
  }

  .statuTextExpired {
    font-weight: bold;
    color: $red;
  }

  .statuTextValid {
    font-weight: bold;
    color: $green;
  }
}
</style>
import { divide } from "lodash";import { now } from "lodash";
